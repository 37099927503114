import React, { useState } from "react";

import BookingButton from "../../molecules/BookingButton/BookingButton";
import GenericModal from "../../molecules/GenericModal/GenericModal";
import BookingDatesGuestsDisplay from "../../molecules/BookingDatesGuestsDisplay/BookingDatesGuestsDisplay";
import BookingForm from "../BookingForm/BookingForm";

import PropTypes from "prop-types";
import "./BookingWidget.css";

const BookingWidget = ({ selectedRange, guests, setGuests }) => {
  const [bookingFormIsOpen, setBookingFormIsOpen] = useState(false);

  return (
    <div className="booking-widget-container">
      <BookingDatesGuestsDisplay
        selectedRange={selectedRange}
        guests={guests}
        setGuests={setGuests}
      />
      <BookingButton
        text="Contact Owner"
        onClick={() => {
          setBookingFormIsOpen(true);
        }}
      />
      <GenericModal
        isOpen={bookingFormIsOpen}
        onClose={() => setBookingFormIsOpen(false)}
      >
        <BookingForm
          selectedRange={selectedRange}
          guests={guests}
          setGuests={setGuests}
          onSubmit={() => setBookingFormIsOpen(false)}
        />
      </GenericModal>
    </div>
  );
};
BookingWidget.propTypes = {
  selectedRange: PropTypes.array,
  guests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setGuests: PropTypes.func.isRequired,
};

export default BookingWidget;

import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import "./ExpandableDescription.css";
import PropTypes from "prop-types";
import GenericModal from "../../molecules/GenericModal/GenericModal";

Modal.setAppElement("#root");

function ExpandableDescription({ children }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef(null); // Create a ref for the description div

  useEffect(() => {
    // Check if the content overflows its container
    const checkOverflow = () => {
      const element = descriptionRef.current;
      if (element) {
        const isOverflow = element.scrollHeight > element.clientHeight;
        setIsOverflowing(isOverflow);
      }
    };

    checkOverflow();
    // You might want to add a resize event listener if the container's size can change
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [children]); // Depend on children, so it updates when content changes

  return (
    <div className="expandable-description-container">
      <div className="description" ref={descriptionRef}>
        {children}
      </div>
      {isOverflowing && (
        <span className="view-more" onClick={() => setModalIsOpen(true)}>
          Read more
        </span>
      )}
      <GenericModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <div className="modal-content">{children}</div>
      </GenericModal>
    </div>
  );
}

ExpandableDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExpandableDescription;

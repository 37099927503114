import React from "react";
import "./FeatureLong.css";
import FeatureIcon from "../../atoms/FeatureIcon/FeatureIcon";
import PropTypes from "prop-types";

function FeatureLong({ iconName, title, description }) {
  return (
    <div className="feature-container">
      <div className="feature-icon">
        <FeatureIcon iconName={iconName} />
      </div>
      <div className="feature-text">
        <div className="feature-title">
          <h3>{title}</h3>
        </div>
        <div className="feature-description">{description}</div>
      </div>
    </div>
  );
}
FeatureLong.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default FeatureLong;

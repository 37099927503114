import React from "react";
import PropTypes from "prop-types";
import "./FormLabel.css";

const FormLabel = ({ text }) => {
  return <div className="form-label">{text}</div>;
};

FormLabel.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FormLabel;

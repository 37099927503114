import React from "react";
import PropTypes from "prop-types";
import FormLabel from "../../atoms/FormLabel/FormLabel";
import InputNumber from "../../atoms/InputNumber/InputNumber";
import "./GuestsChooser.css";

const GuestsChooser = ({ guests, maxGuests, setGuests }) => {
  const handleGuestChange = (e) => {
    const input = e.target.value;
    const inputGuests = parseInt(input, 10);

    if (input === "") {
      setGuests("");
      return;
    }

    if (!isNaN(inputGuests)) {
      setGuests(Math.min(maxGuests, Math.max(1, inputGuests)));
    }
  };

  return (
    <div className="guests-chooser">
      <FormLabel text="Guests" />
      <InputNumber
        value={guests}
        onChange={handleGuestChange}
        min={1}
        max={maxGuests}
      />
    </div>
  );
};

GuestsChooser.propTypes = {
  guests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  maxGuests: PropTypes.number.isRequired,
  setGuests: PropTypes.func,
};

export default GuestsChooser;

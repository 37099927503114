import React from "react";
import PropType from "prop-types";
import "./ShareOption.css";
import ShareIcon from "../../atoms/ShareIcon/ShareIcon";

function ShareOption({ text, iconName, action }) {
  return (
    <button className="share-option" onClick={action}>
      <ShareIcon iconName={iconName} />
      <div className="share-option-text">{text}</div>
    </button>
  );
}
ShareOption.propTypes = {
  text: PropType.string.isRequired,
  iconName: PropType.string.isRequired,
  action: PropType.func.isRequired,
};

export default ShareOption;

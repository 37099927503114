const today = new Date();

const isDateUnavailable = (date) => {
  return bookings.some((booking) => {
    const start = new Date(booking.startDate).setHours(0, 0, 0, 0);
    const end = new Date(booking.endDate).setHours(23, 59, 59, 999);
    const checkDate = new Date(date).setHours(12, 0, 0, 0); // Adjusting the hours to avoid timezone issues
    return checkDate >= start && checkDate <= end;
  });
};

const bookings = [
  {
    // Gisser
    startDate: new Date(2024, 5, 10),
    endDate: new Date(2024, 5, 23),
  },
];

export const siteConfig = {
  listing: {
    title: "Baka Atrium Townhouse",
    shareUrl: "https://goldhatrentals.com",
    longTitle: "Entire rental unit in Jerusalem, Israel",
    longSubtitle: "10+ guests · 5 bedrooms · 10 beds · 3.5 baths",
    features: [
      {
        iconName: "family",
        title: "Great for Large Families",
        description:
          "10 beds plus 3 child-mattresses for slumber-party-style attic sleeping",
      },
      {
        iconName: "freeParking1",
        title: "Free Parking",
        description: "1 Private, free parking spot in front of the building",
      },
      {
        iconName: "kitchen",
        title: "Kosher Kitchen",
        description:
          "Kosher kitchen making meal preparation seamless and stress-free",
      },
    ],
    summary: (
      <>
        <p>
          Discover our versatile, 3-level townhouse in Jerusalem&apos;s serene
          Baka neighborhood, ideal for families and groups ranging from 4 to 10+
          guests. Offering exceptional value, our home balances
          cost-effectiveness for smaller families with the spaciousness large
          groups cherish. With 5 bedrooms, 3.5 bathrooms, and a layout once home
          to a family of 7, it promises comfort without feeling overwhelming.
          Enjoy a fully equipped kosher kitchen, Sukkah availability, and
          communal gathering areas, complemented by modern conveniences like
          high-speed internet and private parking. Perfectly situated a short
          walk from Derech Beit Lechem cafes, the bustling Talpiyot Shopping
          District, The Tachana Rishona (First Station), Emek Refa&apos;im, the
          Haas Promenade (The &quot;Tayelet&quot;) and a manageable 45-min walk
          from the Kotel, this townhouse merges hotel ease with the authentic
          warmth of a true Jerusalem home, catering to all family sizes without
          compromising on comfort or budget.
        </p>
      </>
    ),
    layout: (
      <>
        <p>
          Nestled in the tranquil yet vibrant Baka neighborhood of Jerusalem,
          our 3-level townhouse is a haven of peace and flexibility, designed
          with the comfort and convenience of large families in mind. This
          beautifully appointed home boasts 5 bedrooms, 3.5 bathrooms, and a
          variety of living spaces, making it the perfect base for your trip to
          Israel.
        </p>
        <h4>Level 1</h4>
        <p>
          Upon entering, guests are welcomed into a spacious first-level living
          area that seamlessly blends the kitchen and dining/living room,
          complemented by a convenient powder room. The modern kitchen is
          equipped to cater to all your culinary needs, whether preparing a
          Shabbat dinner or a quick snack. The dining and living space, bathed
          in natural light, invites families to gather, relax, and share the
          day&apos;s experiences.
        </p>
        <h4>Level 2</h4>
        <p>
          The second and third levels are thoughtfully laid out to accommodate
          up to 10 guests with lightweight, modular beds for flexible sleeping
          arrangements. The second level features four cozy bedrooms:
        </p>
        <ul>
          <li>
            the first offers options for single or double occupancy without A/C
            but with ceiling fan and comforting warmth from a radiator;
          </li>
          <li>
            the second, versatile enough for one to three guests, includes A/C,
            ceiling fan and a radiator;
          </li>
          <li>
            the third, a snug room for one, echoes the charm of the first -
            which can be configured as an office space when not being used to
            sleep guests;
          </li>
          <li>
            and the fourth, a delightful retreat for one or two, boasts an
            en-suite bathroom with shower, A/C, and a private balcony to savor
            the serene neighborhood vibe.
          </li>
          <li>
            A centrally located full bath with a bathtub/shower and a quaint
            atrium/common/desk area complete this floor, providing a perfect
            spot for quieter moments or reading.
          </li>
        </ul>
        <h4>Level 3</h4>
        <p>
          The converted attic on the third level, recommended as the master
          suite, features low ceilings that add a cozy, intimate feel, suitable
          for two or three guests. This level includes a large common area,
          alongside a master bathroom with a large, newly renovated shower,
          adding a touch of luxury. The convenience of a washer/dryer and
          collapsible counters for laundry make longer stays comfortable and
          practical. The common area floorspace can be lined with lighter-weight
          mattresses (3 available) for sleeping extra children
          slumber-party-style.
        </p>
        <p>
          Each space in our townhouse has been designed with families in mind,
          from the flexible sleeping arrangements to the communal living areas,
          ensuring that every member, from the youngest to the eldest, feels at
          home. Our townhouse not only serves as a comfortable retreat but also
          as a gateway to the cultural and spiritual richness of Jerusalem,
          located just moments away from shops, cafes, and the city’s most
          revered sites.
        </p>
      </>
    ),
    amenities: (
      <>
        <p>
          Our meticulously designed townhouse in the serene Baka neighborhood is
          more than just a place to stay; it&#39;s a home that understands and
          respects the traditions and needs of Jewish families. Recently
          refurbished with a fresh coat of paint and updated fixtures, the
          property combines modern comforts with thoughtful amenities to ensure
          a memorable and comfortable stay in Jerusalem.
        </p>

        <p>
          <strong>Kosher Kitchen:</strong> Fully equipped and designed for
          observance, our kitchen meets all your dietary needs, making meal
          preparation seamless and stress-free.
        </p>

        <p>
          <strong>Sukkah Availability:</strong> For those wishing to celebrate
          Sukkot with us, a ground-level sukkah can be arranged on the side of
          the building for an additional fee, allowing you to observe the
          holiday in the privacy and comfort of your temporary home.
        </p>

        <p>
          <strong>Space for Celebrations:</strong> The large living/dining area
          is not just a place for relaxation but also perfectly suited for
          hosting larger gatherings, such as a Bar/Bat-Mitzvah Kiddush, large
          Shabbat meal, or a Passover Seder, accommodating your family&#39;s
          festive occasions, with the ability to extend seating space towards
          the kitchen and/or into the living room.
        </p>

        <p>
          <strong>Connectivity:</strong> Stay connected with the outside world
          through high-speed internet/WiFi access throughout the townhouse,
          ensuring you&#39;re always just a click away from whatever you need.
        </p>

        <p>
          <strong>Private Parking Space:</strong> A rarity in the bustling city
          of Jerusalem, our townhouse offers one private parking space,
          eliminating the hassle of finding parking in the area and giving you
          peace of mind.
        </p>

        <p>
          <strong>Accessibility/Children:</strong> Please note that the property
          is a walk-up, with the entrance located up a 1.5 flight of stairs,
          offering a blend of privacy and security. While we do not have child
          safety gates on stairs, a crib and highchair are available upon
          request to accommodate our youngest guests.
        </p>

        <p>
          These features, from the kosher kitchen and sukkah availability to the
          convenience of private parking and high-speed internet, are all
          designed to make your stay in Jerusalem as comfortable and fulfilling
          as possible. Our townhouse is not just a place to sleep but a space
          where families can live, celebrate, and create memories in the heart
          of one of the world&#39;s most ancient and vibrant cities.
        </p>
      </>
    ),
    location: (
      <>
        <p>
          Nestled in the serene Baka neighborhood of Jerusalem, this charming
          property offers an ideal retreat for families seeking both tranquility
          and convenience. Baka has evolved into a sought-after locale,
          attracting a sophisticated mix of wealthy Anglo and French retirees
          and tourists, all drawn by its quiet, genteel atmosphere and its
          vibrant community life centered around the local synagogues. Despite
          its peaceful setting, the property remains remarkably accessible to
          the heart of Jerusalem&#39;s cultural and spiritual life.
        </p>

        <p>
          Located on the tranquil Ben Yefune Street, just a stone&#39;s throw
          from Derech Beit Lechem, famous for its quaint shops and inviting
          cafes, guests have the best of both worlds. A mere 5-minute stroll
          leads to the bustling Talpiyot Shopping District, with its malls,
          restaurants, and array of discount outlets, offering a convenient
          urban experience. For those in pursuit of leisure and entertainment,
          The Tachana Rishona (First Station) leisure complex, a transformed
          Ottoman-era train station, is just a 10-minute walk away, bustling
          with restaurants, shops, and activities, a perfect spot for families
          to unwind.
        </p>

        <p>
          Cultural experiences abound with a 12-minute walk to Emek Refaim
          Street, the heart of the fashionable German Colony. Here, amidst the
          historic German Templar buildings, one can enjoy trendy boutiques,
          cafes, and a cinema, offering a slice of modern life within the
          ancient city. Similarly close is the beautiful Haas Promenade (The
          &quot;Tayelet&quot;), offering breathtaking views of the Old City from
          its 1,300-foot-long vantage point. For those inclined to explore
          Jerusalem&#39;s spiritual heart, the Kotel is a manageable 45-minute
          walk, providing a direct connection to the city&#39;s historic and
          religious core.
        </p>

        <p>
          This location truly offers an unparalleled blend of peace, cultural
          richness, and accessibility, making it an ideal base for Jewish
          families seeking an immersive Jerusalem experience that balances
          modern comfort with the profound heritage of the Holy City.
        </p>
      </>
    ),
    locationSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3392.518121246648!2d35.217561313133665!3d31.756351635334095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x150328217f0eeced%3A0xc7631e3ae20ead1f!2sBen%20Yefune%20St%205%2C%20Jerusalem%2C%20Israel!5e0!3m2!1sen!2seg!4v1710069230862!5m2!1sen!2seg",
  },

  defaultStartDate: new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7,
  ),

  defaultEndDate: new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 14,
  ),

  isDateUnavailable: isDateUnavailable,
};

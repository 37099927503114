const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const submitBooking = async (bookingData) => {
  try {
    const response = await fetch(`${apiEndpoint}/booking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bookingData),
    });

    if (!response.ok) {
      throw new Error("Booking submission failed");
    }

    return await response.json();
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error; // Rethrow to handle it in the component
  }
};

export { submitBooking };

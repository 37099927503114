import React from "react";
import "./ShareOptionsModal.css";
import PropTypes from "prop-types";
import GenericModal from "../../molecules/GenericModal/GenericModal";
import ShareOption from "../../molecules/ShareOption/ShareOption";

function ShareOptionsModal({ shareUrl, title, isOpen, onClose }) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert("Link copied to clipboard!");
    });
  };

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <GenericModal isOpen={isOpen} onClose={onClose}>
      <section>
        <h2>Share this place</h2>
        <div className="share-options-header">
          <img />
          <div className="share-options-title">{title}</div>
        </div>
        <div className="share-options">
          <ShareOption
            text="Copy Link"
            iconName="copyLink"
            action={copyToClipboard}
          />
          <ShareOption
            text="WhatsApp"
            iconName="whatsApp"
            action={shareOnWhatsApp}
          />
        </div>
      </section>
    </GenericModal>
  );
}
ShareOptionsModal.propTypes = {
  shareUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShareOptionsModal;

import React, { useState } from "react";
import "./ListingHeader.css";
import ShareButton from "../../molecules/ShareButton/ShareButton";
import ShareOptionsModal from "../ShareOptionsModal/ShareOptionsModal";
import PropTypes from "prop-types";

function ListingHeader({ title, shareUrl = "https://goldhatrentals.com" }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="listing-header">
      <h1 className="listing-title">{title}</h1>
      <ShareButton onClick={() => setIsModalOpen(true)} />
      <ShareOptionsModal
        shareUrl={shareUrl}
        title={title}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

ListingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  shareUrl: PropTypes.string,
};

export default ListingHeader;

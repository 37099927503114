import React from "react";
import DisplayDateRange from "../../molecules/DisplayDateRange/DisplayDateRange";
import GuestsChooser from "../../molecules/GuestsChooser/GuestsChooser";
import PropTypes from "prop-types";
import "./BookingDatesGuestsDisplay.css";

const BookingDatesGuestsDisplay = ({ selectedRange, guests, setGuests }) => {
  return (
    <div className="booking-dates-guests-display">
      <DisplayDateRange
        startDate={selectedRange[0]}
        endDate={selectedRange[1]}
      />
      <GuestsChooser guests={guests} setGuests={setGuests} maxGuests={10} />
    </div>
  );
};

const dateRangePropType = (props, propName, componentName) => {
  const propValue = props[propName];

  if (!Array.isArray(propValue) || propValue.length !== 2) {
    return new Error(
      `${propName} in ${componentName} needs to be an array of two items.`,
    );
  }

  if (!propValue.every((item) => item instanceof Date)) {
    return new Error(
      `${propName} in ${componentName} needs to contain Date objects.`,
    );
  }
};

BookingDatesGuestsDisplay.propTypes = {
  selectedRange: dateRangePropType,
  guests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setGuests: PropTypes.func,
};

export default BookingDatesGuestsDisplay;

import React from "react";
import PropTypes from "prop-types";

function TitleTile({ title, description }) {
  return (
    <div className="title-tile">
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
}
TitleTile.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TitleTile;

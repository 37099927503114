import React from "react";
import PropTypes from "prop-types";
import "./GenericModal.css";
import CloseButton from "../../atoms/CloseButton/CloseButton";

const GenericModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="generic-modal-overlay" onClick={onClose}>
      <div
        className="generic-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <CloseButton onClose={onClose} />
        {children}
      </div>
    </div>
  );
};
GenericModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default GenericModal;

// src/App.js
import React from "react";
import "./App.css";
import { siteConfig } from "./siteConfig";
import ListingHeader from "./components/organisms/ListingHeader/ListingHeader";
import { galleryImages } from "./generated/galleryImages";
import ImageGallery from "./components/organisms/ImageGallery/ImageGallery";
import MainContent from "./components/templates/MainContent/MainContent";
import ExpandableDescription from "./components/organisms/ExpandableDescription/ExpandableDescription";
import ReactGA from "react-ga4";

ReactGA.initialize("G-HZDVD961TS");
ReactGA.send({
  hitType: "pageview",
  page: "/",
  title: "Baka Atrium Townhouse",
});

function App() {
  const { listing } = siteConfig;

  return (
    <div className="App">
      <ListingHeader title={listing.title} shareUrl={listing.shareUrl} />
      <ImageGallery images={galleryImages} maxDisplay={5} />
      <MainContent />

      <h2>About the Neighborhood</h2>
      <ExpandableDescription>{listing.location}</ExpandableDescription>
      <iframe
        src={listing.locationSrc}
        width="100%"
        height="450"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <hr />
    </div>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";
import "./CloseButton.css";

const CloseButton = ({ onClose }) => {
  return (
    <button className="modal-close-button" onClick={onClose}>
      &times;
    </button>
  );
};
CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CloseButton;

import React from "react";
import PropTypes from "prop-types";
import "./BookingButton.css"; // Assuming you'll create specific CSS for this component

const BookingButton = ({ text, onClick }) => {
  return (
    <button className="booking-button" onClick={onClick}>
      {text}
    </button>
  );
};

BookingButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default BookingButton;

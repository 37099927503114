import React from "react";
import PropTypes from "prop-types";
import DisplayDate from "../../atoms/DisplayDate/DisplayDate";
import FormLabel from "../../atoms/FormLabel/FormLabel";
import "./DisplayDateRange.css";

const DisplayDateRange = ({ startDate, endDate }) => {
  return (
    <div className="date-range-display">
      <div className="date-display">
        <FormLabel text="Check-in" />
        <DisplayDate date={startDate} />
      </div>
      <div className="date-display">
        <FormLabel text="Checkout" />
        <DisplayDate date={endDate} />
      </div>
    </div>
  );
};

DisplayDateRange.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
};

export default DisplayDateRange;

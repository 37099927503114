import React from "react";
import PropTypes from "prop-types";
import "./DisplayDate.css";

const DisplayDate = ({ date }) => {
  const formatDate = (date) => {
    if (
      date &&
      Object.prototype.toString.call(date) === "[object Date]" &&
      !isNaN(date)
    ) {
      return date.toLocaleDateString();
    } else if (typeof date === "string" || typeof date === "number") {
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate)) {
        return parsedDate.toLocaleDateString();
      }
    }
    return "";
  };

  return <div className="display-date">{formatDate(date)}</div>;
};

DisplayDate.propTypes = {
  date: PropTypes.instanceOf(Date),
};

export default DisplayDate;

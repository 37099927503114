import React from "react";
import PropTypes from "prop-types";
import "./TimezoneInput.css";

const TimezoneInput = ({ id, name, label, value, onChange }) => {
  const timezones = [
    { label: "US Pacific", value: "US/Pacific" },
    { label: "US Mountain", value: "US/Mountain" },
    { label: "US Central", value: "US/Central" },
    { label: "US Eastern", value: "US/Eastern" },
    { label: "London", value: "Europe/London" },
    { label: "Paris", value: "Europe/Paris" },
    { label: "Berlin", value: "Europe/Berlin" },
    { label: "Madrid", value: "Europe/Madrid" },
    { label: "Rome", value: "Europe/Rome" },
    { label: "Jerusalem", value: "Asia/Jerusalem" },
    { label: "South Africa", value: "Africa/Johannesburg" },
    { label: "Sydney", value: "Australia/Sydney" },
    { label: "Melbourne", value: "Australia/Melbourne" },
    { label: "Other (Specify Above)", value: "Other" },
  ];

  return (
    <div className="input-timezone">
      <label htmlFor={id}>{label}</label>
      <select id={id} name={name} value={value} onChange={onChange}>
        {timezones.map((tz) => (
          <option key={tz.value} value={tz.value}>
            {tz.label}
          </option>
        ))}
      </select>
    </div>
  );
};

TimezoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TimezoneInput;

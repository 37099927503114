import React, { useState } from "react";
import { submitBooking } from "../../../services/bookingService";
import InputText from "../../atoms/InputText/InputText";
import BookingDatesGuestsDisplay from "../../molecules/BookingDatesGuestsDisplay/BookingDatesGuestsDisplay";
import TimezoneInput from "../../atoms/TimezoneInput/TimezoneInput";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import "./BookingForm.css";

const BookingForm = ({ selectedRange, guests, setGuests, onSubmit }) => {
  const [formData, setFormData] = useState({
    checkinDate: selectedRange[0],
    checkoutDate: selectedRange[1],
    guests: guests,
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    contactTZ: "Asia/Jerusalem",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    formData.checkinDate = selectedRange[0].toLocaleDateString();
    formData.checkoutDate = selectedRange[1].toLocaleDateString();
    formData.guests = guests;

    try {
      const response = await submitBooking({
        ...formData,
      });
      ReactGA.event("request_quote", {
        category: "request_quote",
        action: "button_click",
      });
      console.log("Booking submitted successfully:", response);
      alert("Request successfully submitted!");
    } catch (error) {
      console.error("Error submitting booking:", error);
      alert("Error submitting booking:", error);
    } finally {
      setIsSubmitting(false);
      if (onSubmit) onSubmit();
    }
  };

  return (
    <form className="booking-form" onSubmit={handleSubmit}>
      <BookingDatesGuestsDisplay
        selectedRange={selectedRange}
        guests={guests}
        setGuests={setGuests}
      />
      <InputText
        id="fullName"
        name="fullName"
        value={formData.fullName}
        label="Full Name"
        onChange={handleChange}
      />
      <InputText
        id="emailAddress"
        name="emailAddress"
        value={formData.emailAddress}
        label="Email Address"
        onChange={handleChange}
      />
      <InputText
        id="phoneNumber"
        name="phoneNumber"
        value={formData.phoneNumber}
        label="Phone Number"
        onChange={handleChange}
      />
      <TimezoneInput
        id="contactTZ"
        name="contactTZ"
        value={formData.contactTZ}
        label="Contact Timezone"
        onChange={handleChange}
      />
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Submitting..." : "Submit"}
      </button>
    </form>
  );
};
BookingForm.propTypes = {
  selectedRange: PropTypes.array.isRequired,
  guests: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setGuests: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default BookingForm;

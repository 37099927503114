import React from "react";
import PropTypes from "prop-types";
import "./InputNumber.css";

const InputNumber = ({ value, onChange, min, max }) => {
  return (
    <input
      className="input-number"
      type="number"
      value={value}
      onChange={onChange}
      min={min}
      max={max}
    />
  );
};

InputNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default InputNumber;

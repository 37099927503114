import React, { useState } from "react";
import PropTypes from "prop-types";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./ImageGallery.css";

const ImageGallery = ({ images, maxDisplay = 5 }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const slides = images.map((image) => ({
    src: image.url,
    alt: image.alt,
  }));

  return (
    <div className="image-gallery">
      <div className="main-image">
        <img
          src={images[0].url}
          alt={images[0].alt}
          onClick={() => openLightbox(0)}
        />
      </div>
      <div className="thumbnail-images">
        {images.slice(1, maxDisplay).map((image, index) => (
          <div className="thumbnail" key={index}>
            <img
              key={index}
              src={image.url}
              alt={image.alt}
              onClick={() => {
                openLightbox(index + 1);
              }}
            />
          </div>
        ))}
      </div>
      <button className="show-all-photos" onClick={() => setLightboxOpen(true)}>
        Show all photos
      </button>

      {lightboxOpen && (
        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={slides}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </div>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ).isRequired,
  maxDisplay: PropTypes.number,
};

export default ImageGallery;

export const galleryImages = [
  { url: "/gallery/0000building view.jpg", alt: "Image description 1" },
  { url: "/gallery/0001Kitchen2.jpg", alt: "Image description 2" },
  { url: "/gallery/0002LivingDining1.jpg", alt: "Image description 3" },
  { url: "/gallery/0003Atrium.jpg", alt: "Image description 4" },
  { url: "/gallery/0004Master1.jpg", alt: "Image description 5" },
  { url: "/gallery/0101Kitchen1.jpg", alt: "Image description 6" },
  { url: "/gallery/0101Kitchen3.jpg", alt: "Image description 7" },
  { url: "/gallery/0102-1Level1.jpg", alt: "Image description 8" },
  { url: "/gallery/0102-2Level1.jpg", alt: "Image description 9" },
  { url: "/gallery/0102-3Level1.jpg", alt: "Image description 10" },
  { url: "/gallery/0103LivingRoom1.jpg", alt: "Image description 11" },
  { url: "/gallery/02002ndFloorView2.jpg", alt: "Image description 12" },
  { url: "/gallery/0201Lia1.jpg", alt: "Image description 13" },
  { url: "/gallery/0201Lia2.jpg", alt: "Image description 14" },
  { url: "/gallery/0202Yara1.jpg", alt: "Image description 15" },
  { url: "/gallery/0202Yara2.jpg", alt: "Image description 16" },
  { url: "/gallery/0203Jack1.jpg", alt: "Image description 17" },
  { url: "/gallery/0203Jack2.jpg", alt: "Image description 18" },
  { url: "/gallery/0204Mirpeset.jpg", alt: "Image description 19" },
  { url: "/gallery/0204Remy1.jpg", alt: "Image description 20" },
  { url: "/gallery/0204Remy2.jpg", alt: "Image description 21" },
  { url: "/gallery/0204Remy3.jpg", alt: "Image description 22" },
  { url: "/gallery/0204Remy4.jpg", alt: "Image description 23" },
  { url: "/gallery/0204Remy5.jpg", alt: "Image description 24" },
  { url: "/gallery/0205KidsBath1.jpg", alt: "Image description 25" },
  { url: "/gallery/0301Master1.jpg", alt: "Image description 26" },
  { url: "/gallery/0302Attic1.jpg", alt: "Image description 27" },
  { url: "/gallery/0303MasterBath1.jpg", alt: "Image description 28" },
  { url: "/gallery/0303MasterBath2.jpg", alt: "Image description 29" },
  { url: "/gallery/0303MasterBath3.jpg", alt: "Image description 30" },
];

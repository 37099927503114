import React, { useState } from "react";
import "./MainContent.css";
import { siteConfig } from "../../../siteConfig";
import TitleTile from "../../atoms/TitleTile/TitleTile";
import FeatureLong from "../../organisms/FeatureLong/FeatureLong";
import ExpandableDescription from "../../organisms/ExpandableDescription/ExpandableDescription";
import BookingWidget from "../../organisms/BookingWidget/BookingWidget";
import AvailabilityCalendar from "../../organisms/AvailabilityCalendar/AvailabilityCalendar";

function MainContent() {
  const { listing } = siteConfig;

  const [selectedRange, setSelectedRange] = useState([
    siteConfig.defaultStartDate,
    siteConfig.defaultEndDate,
  ]);

  const [guests, setGuests] = useState(5);

  return (
    <>
      <div className="main-content">
        <div className="content">
          <TitleTile
            title={listing.longTitle}
            description={listing.longSubtitle}
          />
          <hr />

          {listing.features.map(({ iconName, title, description }, index) => (
            <FeatureLong
              key={index}
              iconName={iconName}
              title={title}
              description={description}
            />
          ))}
          <hr />

          <h2>Description</h2>
          <ExpandableDescription>{listing.summary}</ExpandableDescription>
          <hr />

          <h2>Living and sleeping spaces</h2>
          <ExpandableDescription>{listing.layout}</ExpandableDescription>
          <hr />

          <h2>Amenities for your use</h2>
          <ExpandableDescription>{listing.amenities}</ExpandableDescription>
          <hr />

          <h2>Availability</h2>
          <AvailabilityCalendar
            onChange={setSelectedRange}
            initialRange={selectedRange}
            isDateUnavailable={siteConfig.isDateUnavailable}
          />
          <hr className="main-content last-hr" />
        </div>

        <BookingWidget
          selectedRange={selectedRange}
          guests={guests}
          setGuests={setGuests}
        />
      </div>
      <hr className="main-content" />
    </>
  );
}

export default MainContent;

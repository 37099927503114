import React from "react";
import PropTypes from "prop-types";
import "./InputText.css";

const InputText = ({ id, name, label, value, onChange }) => (
  <div className="input-text">
    <label htmlFor={id}>{label}</label>
    <input id={id} name={name} type="text" value={value} onChange={onChange} />
  </div>
);

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default InputText;

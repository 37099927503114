import React from "react";
import FeatureIcon from "../../atoms/FeatureIcon/FeatureIcon";
import PropTypes from "prop-types";
import "./ShareButton.css";

const ShareButton = ({ onClick }) => {
  return (
    <button className="share-button" onClick={onClick}>
      <FeatureIcon iconName="share" /> Share
    </button>
  );
};
ShareButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ShareButton;

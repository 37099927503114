import React, { useState, useEffect } from "react";
import "./AvailabilityCalendar.css";
import Calendar from "react-calendar";
import PropTypes from "prop-types";

const mobileWidth = 768;

function AvailabilityCalendar({ onChange, initialRange, isDateUnavailable }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < mobileWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validateRangeChange = (range) => {
    const [start, end] = range;
    let date = new Date(start);
    while (date <= end) {
      if (isDateUnavailable(date)) {
        alert(
          "Your dates selection includes dates for which the unit is unavailable. Please select a new date range.",
        );
        onChange([null, null]); // Reset selection
        return;
      }
      date = new Date(date.setDate(date.getDate() + 1));
    }

    onChange(range);
  };

  return (
    <>
      <Calendar
        showDoubleView={!isMobile}
        tileDisabled={({ date }) => isDateUnavailable(date)}
        selectRange={true}
        onChange={validateRangeChange}
        value={initialRange.map((date) => new Date(date))}
      />
    </>
  );
}
AvailabilityCalendar.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialRange: PropTypes.array,
  isDateUnavailable: PropTypes.func,
};

export default AvailabilityCalendar;
